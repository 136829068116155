<template>
    <div class="card">
        <div class="card-header">{{ $t('crud.pages._create') }}</div>
        <div class="card-body">
            <pages-form-component @submit="create" :record="record" :loading="loading" />
        </div>
    </div>
</template>

<script>;
    import {functions, config} from '../crud/mixin_config';
    import PagesFormComponent from "./Form";

    export default {
        name: 'pages-create-component',
        mixins: [functions],
        components: {PagesFormComponent},
        data() {
            return {
                crud: config('pages'),
                record: {
                    // Empty model.
                }
            };
        }
    }
</script>
