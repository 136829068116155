<template>
    <form @submit.prevent="submit()">
        <b-form-group :label="$t('crud.pages.url')">
            <b-form-input v-model="record.url" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.pages.title')">
            <b-form-input v-model="record.title" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.pages.page_title')">
            <b-form-input v-model="record.page_title"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.pages.meta_description')">
            <b-form-input v-model="record.meta_description"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.pages.head_content')">
            <b-form-textarea v-model="record.head_content" :rows="3"></b-form-textarea>
        </b-form-group>
        <b-form-group :label="$t('crud.pages.content')">
            <div class="row">
                <div class="col-10">
                    <editor v-model="record.content"></editor>
                </div>
                <div class="col-2">
                    <div class="card">
                        <div class="card-header">{{ $t('crud.pages.bb_codes') }}</div>
                        <div class="card-body">
                            <p v-for="bb_code in bb_codes">
                                <span class="badge badge-secondary">{{ bb_code }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </b-form-group>
        <b-form-group :label="$t('crud.pages.body_end_content')">
            <b-form-textarea v-model="record.body_end_content" :rows="3"></b-form-textarea>
        </b-form-group>
        <b-form-group>
            <b-form-checkbox v-model="record.type" value="1" unchecked-value="2">
                {{ $t('crud.pages.type_1') }}
            </b-form-checkbox>
        </b-form-group>
        <b-form-group>
            <b-form-checkbox v-model="record.status" value="1" unchecked-value="0">
                {{ $t('crud.pages.status_1') }}
            </b-form-checkbox>
        </b-form-group>
        <loading-button class="btn btn-primary" type="submit" :loading="loading">{{ $t('crud.save') }}</loading-button>
    </form>
</template>

<script>
    export default {
        props: ['record', 'loading'],
        mounted() {
            this.$http.get('/bb-codes').then((response) => {
                this.bb_codes = response.data;
            });
        },
        data() {
            return {
                bb_codes: []
            }
        },
        methods: {
            submit() {
                this.$emit('submit');
            }
        }
    }
</script>
